<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
            autocomplete="off"
        >
            <b-sidebar id="send_invoice_whats" aria-labelledby="sidebar-no-header-title" no-header backdrop width="850px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.send_whatsapp}}</span>
                        </div>
                        <div @click="hide" id="hideaddHomeTask" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{ lang.close }}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <v-row style="direction:rtl" class="m-2">
                        <v-col cols="12" md="4">
                            <label>{{lang.what_to_send}}</label>
                            <b-form-select class="selborder"
                                v-model="what_to_send"
                                :options="sendTo"
                                @change="changeSent()"
                                />
                        </v-col>
                        <v-col cols="12" md="4" v-if="what_to_send == 1">
                            <label>{{lang.sent_type}}</label>
                            <b-form-select class="selborder"
                                v-model="sent_type"
                                :options="sentTypes"
                                @change="changeType()"
                                />
                        </v-col>
                    </v-row>
                    <v-row style="direction:rtl" class="m-2" v-if="what_to_send == 1">
                        <v-col cols="12" md="12">
                            <label>{{lang.message_title}}</label>
                            <b-form-input class="inborder"
                                v-model="message_title"
                                />
                        </v-col>
                        <v-col cols="12" md="12" v-if="showNumList && what_to_send == 1">
                            <label>{{lang.number_list}}</label>
                            <b-form-textarea class="inborder"
                                v-model="number_list"
                                style="direction:ltr"
                                />
                                <div><small style="color:red">{{ lang.number_list_notes }}</small></div>
                        </v-col>
                        <v-col cols="12" md="12" v-if="what_to_send == 1">
                            <label>{{lang.message_text}}</label>
                            <b-form-textarea class="inborder"
                                v-model="message_text"
                                style="height:150px;"
                                />
                        </v-col>
                        <v-col cols="12" md="12" v-if="showImage && what_to_send == 1">
                            <label>{{lang.image_url}}</label>
                            <b-form-input class="inborder"
                                v-model="image_url"
                                style="direction:ltr"
                                />
                        </v-col>
                        <v-col cols="12" md="12" v-if="showDoc && what_to_send == 1">
                            <label>{{lang.document_url}}</label>
                            <b-form-input class="inborder"
                                v-model="document_url"
                                style="direction:ltr"
                                />
                        </v-col>
                        <v-col cols="12" md="12" v-if="showVed && what_to_send == 1">
                            <label>{{lang.vedio_url}}</label>
                            <b-form-input class="inborder"
                                v-model="vedio_url"
                                style="direction:ltr"
                                />
                        </v-col>
                        <v-col cols="12" md="12" v-if="(showDoc || showVed || showImage) && what_to_send == 1">
                            <label>أو بإمكانك تحميل ملف</label>
                            <input type="file"
                                ref="attached"
                                style="width:98%;border:1px solid #ccc;padding:5px;"
                                @change="selectFiles()"
                                >
                        </v-col>
                    </v-row>
                </template>
                <template #footer="">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="sendMsg()" variant="success" class="ma-2" style="width:100px;">{{lang.send}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            invoice_id: 0,
            what_to_send: 1,
            number_list: '',
            message_title: '',
            send_to: 0,
            city_list: 0,
            message_text: '',
            industrial: 0,
            showCities: false,
            showIndust: false,
            showDoc: false,
            showImage: false,
            showVed: false,
            showNumList: true,
            image_url: '',
            document_url: '',
            vedio_url: '',
            sent_type: 0,
            client_type: 0,
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
                seconds: '00',
                minutes: (new Date()).getMinutes(),
                hours: (new Date()).getHours(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
                seconds: '00',
                minutes: (new Date()).getMinutes(),
                hours: (new Date()).getHours(),
            },
            citisNames: [{text: 'كل المدن', value: 0}],
            indUstr: [{text: 'كل الصناعيات', value: 0}],
            attached: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },

        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1,this.sd.hours,this.sd.minutes,this.sd.seconds)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1,this.ed.hours,this.ed.minutes,this.ed.seconds)).toISOString().substring(0,10);
        },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      secondsList: function(){
        let t = [];
        for(let i=0;i<60;i++){
            let j = i;
            t.push({
                text: j < 10 ? "0"+j : j,
                value: j < 10 ? "0"+j : j
            })
        }
        return t;
      },
      minutesList: function(){
        let t = [];
        for(let i=0;i<60;i++){
            let j = i;
            t.push({
                text: j < 10 ? "0"+j : j,
                value: j < 10 ? "0"+j : j
            })
        }
        return t;
      },
      hoursList: function(){
        // console.log("aaaa" , (new Date()).getHours());
        let t = [];
        for(let i=0;i<23;i++){
            let j = i;
            t.push({
                text: j < 10 ? "0"+j : j,
                value: j < 10 ? "0"+j : j
            })
        }
        return t;
      },
      sendTo: function(){
        return [
            {text: 'ارسال يدوي',value: 1},
            {text: 'نسخة من الفاتورة ',value: 3},
            {text: 'كود التحقق',value: 4}
        ]
      },
      sentTypes: function(){
        return [
            {text: 'رسالة نصية ',value: 0},
            {text: 'رسالة نصية مع صورة ',value: 1},
            {text: 'رسالة نصية مع مستند ',value: 2},
            {text: 'رسالة نصية مع فيديو',value: 3}
        ]
      },
      clientTypes: function(){
        return [
            {text: 'كل العملاء ',value: 0},
            {text: 'العملاء غير مشترين',value: 1},
            {text: 'العملاء المشترين ',value: 2},
            {text: 'العملاء المنتهيين',value: 3}
        ]
      },
    },
    methods: {
        selectFiles(){
            // const files = this.$refs.attached.files;
            // console.log(files);
            // this.settings.logo_img = [...this.settings.logo_img,...files];
            const files = this.$refs.attached.files;
            this.attached = [...this.attached, ...files];

            // console.log(this.file1);
            
        },
        reset(){
            this.number_list = '0595907777,0566566160,0565100268'
            this.message_title = ''
            this.send_to = 0
            this.city_list = 0
            this.message_text = ''
            this.industrial = 0
            this.showCities = false
            this.showIndust = false
            this.showDoc = false
            this.showImage = false
            this.showVed = false
            this.showNumList = true
            this.image_url = ''
            this.document_url = ''
            this.vedio_url = ''
            this.sent_type = 0
            this.client_type = 0
        },
        sendMsg(){
            if(this.what_to_send == 1 && this.message_text == ''){
                let message = "نص الرسالة مطلوب دائما";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            else if(this.what_to_send == 1 && this.message_title == ''){
                let message = "عنوان الرسالة مطلوب دائما";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            else if(this.what_to_send == 1 && this.send_to == 0 && this.number_list == ''){
                let message = "يجب اضافة رقم على الاقل لقائمة الارقام";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            else if(this.what_to_send == 1 && this.sent_type == 1 && this.image_url == '' && this.attached.length == 0){
                let message = "يجب اضافة عنوان الصورة";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            else if(this.what_to_send == 1 && this.sent_type == 2 && this.document_url == '' && this.attached.length == 0){
                let message = "يجب اضافة عنوان المستند";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            else if(this.what_to_send == 1 && this.sent_type == 3 && this.vedio_url == '' && this.attached.length == 0){
                let message = "يجب اضافة عنوان الفيديو";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "uSendWhatsApp");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));

            post.append('data[number_list]',this.number_list);
            post.append('data[message_title]',this.message_title);
            post.append('data[send_to]',this.send_to);
            post.append('data[city_list]',this.city_list);
            post.append('data[message_text]',this.message_text);
            post.append('data[industrial]',this.industrial);
            post.append('data[image_url]',this.image_url);
            post.append('data[document_url]',this.document_url);
            post.append('data[vedio_url]',this.vedio_url);
            post.append('data[sent_type]',this.sent_type);
            post.append('data[client_type]',this.client_type);
            post.append('data[cardid]',this.cardid);
            post.append('data[customerid]',this.customerid);
            post.append('data[what_to_send]',this.what_to_send);
            post.append('data[invoiceid]',this.invoice_id);
            post.append("data[attached]", typeof this.attached[0] !== 'undefined' ? this.attached[0] : "" );
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    let message = "تمت اضافة الرسالة إلى قائمة الرسائل - تعتمد مدة الارسال على عدد الارقام";
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    this.reset;
                    this.$parent.getAllW();
                    document.getElementById('hideaddHomeTask').click();
                    return false;
                }
            })
        },
        
        changeType(){
            if(this.sent_type == 0){
                this.showImage = false
                this.showDoc = false
                this.showVed = false
            }
            if(this.sent_type == 1){
                this.showImage = true
                this.showDoc = false
                this.showVed = false
            }
            if(this.sent_type == 2){
                this.showImage = false
                this.showDoc = true
                this.showVed = false
            }
            if(this.sent_type == 3){
                this.showImage = false
                this.showDoc = false
                this.showVed = true
            }
        },
        changeSent(){
            if(this.send_to == 0){
                this.showCities = false
                this.showIndust = false
                this.showNumList = true
            }else if(this.send_to == 1){
                this.showCities = false
                this.showIndust = false
                this.showNumList = false
            }else if(this.send_to == 2){
                this.showCities = true
                this.showNumList = false
            }
        },
        changeCities(){
            if(this.send_to == 2 && this.city_list == 1){
                this.showIndust = true
            }else{
                this.showIndust = false
            }
        },
        getStatus(status){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , 'getStaticOptions');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    for(let i=0;i<res.length;i++){
                        if(status == 'citiesNames'){
                            this.citisNames.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                        }
                        if(status == 'inDustrial'){
                            this.indUstr.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                        }
                    }
                }
            )
        },

    },
    created() {
        this.getStatus('citiesNames');
        this.getStatus('inDustrial');
    },
}
</script>